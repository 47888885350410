* {
    box-sizing: border-box;
}


  a:link {
     /* Styles for unvisited links */
    color: #294C60;
  }
  a:visited {
    /* Styles for visited links */
    color: #3c81a8;
  }
  a:link:hover {
    /*Styles for links on hover */
    color: #3c81a8;
  }

  @import url('https://fonts.googleapis.com/css2?family=Mate:ital@0;1&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

  
body {
    margin: 0;
    background-color: #faf5e7;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}


.image {
    border-radius: 7%;
    width: 20%;
    height: 20%;
    /*border: .3rem solid #294C60;  Color of border around the image */
    margin: 2rem;
}

.container {
    margin: 1rem;
    text-align: center
}

.nav {
    background-color: #294C60;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
}
.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.site-title {
    font-size: 1.4rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}

.nav Button {
    color: #FFEFD3;
}

.nav Button.active {
    background-color: #001B2E;
}

.nav Button:hover {
    background-color: #ADB6C4;
}

.section_name {
    text-align: left;
    margin: 0rem;
    margin-bottom: .75rem;
    border: 0rem;
    padding: 0rem;
}
.section_title_h3_h4_h5 {
    text-align: left;
    margin: 0rem;
    border: 0rem;
    padding: 0rem;
}
.section_ul {
    margin: .75rem;
    margin-top: 0rem;
    border: 1rem;
    padding: .25rem;
    text-align: left;
    list-style-type: square;
    list-style-position: inside;
}
.section_li {
    text-align: left;
}
.imbedded_pdf{
    width: 100%;
    height: 600px;
}

/*
Color Palette

001B2E
294C60
ADB6C4
FFEFD3
FFC49B

*/